<template>
  <div class="files">
     <navBar></navBar>
    <div class="content">
      <file-upload></file-upload>
      <md-card>
        <md-card-header>
          <div class="md-subheading">Files</div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout md-alignment-top-right"><md-button v-on:click="loadData"><md-icon>refresh</md-icon></md-button></div>
          <div class="md-layout md-alignment-top-left">
            <div v-if="!(files.length > 0)">
              Nothing here
            </div>
            <md-table v-model="files" md-sort="name" md-sort-order="asc" style="text-align: left" @md-selected="onSelect">

              <md-table-row slot="md-table-row" slot-scope="{ item }" style="text-align: left" md-selectable="single">
                <md-table-cell md-label="Preview" style="text-align: left">
                  <img style="height: 50px; " v-if="isImage(item.type)" :src="config.FILES_LOCATION + item.name" :alt="item.name">
                  <md-icon style="height: 50px" v-else-if="isAudio(item.type)">audiotrack</md-icon>
                  <md-icon style="height: 50px" v-else>description</md-icon>
                </md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="name" style="text-align: left">
                  {{ item.name }}
                </md-table-cell>
                <md-table-cell md-label="Link" md-sort-by="link" style="text-align: left">
                  <a :href="item.link" target="_blank"> {{ item.link }}</a>
                </md-table-cell>
                <md-table-cell md-label="Type" md-sort-by="type" style="text-align: left">
                  {{ item.type }}
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <md-snackbar md-position="center" :md-active.sync="showSnackbar" md-persistent>
      <span>Copied link to clipboard.</span>
      <md-button class="md-primary" @click="showSnackbar = false">Ok</md-button>
    </md-snackbar>
  </div>
</template>
<script>
    import navBar from '@/components/NavBar.vue';
    import fileUpload from '@/components/FilesUpload.vue';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import axios from 'axios'
    // eslint-disable-next-line no-unused-vars
    import config from '@/config/const.js'

    export default {
        name: "Home",
        data () {
            return {
              showSnackbar: false,
              sending: false,
              files: [],
              config: config
            }
        },
        methods:{
          loadData: function () {
            const me = this
            axios.get(me.config.SERVER_ADDRESS + '/files')
              .then(function (response) {
                response.data.forEach(function (elem){
                  me.files.push({name: elem, link: me.config.FILES_LOCATION + elem, type: elem.split('.').pop()})
                })
                // console.log(me.list)
              })
              .catch(function (error) {
                alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
              });
          },
          isImage: function (file) {
            let allowedFormats = ["jpg","JPG","png","PNG","jpeg","JPEG"]
            return allowedFormats.includes(file);
          },
          isAudio: function (file) {
            let allowedFormats = ["mp3","MP3","ogg","OGG"]
            return allowedFormats.includes(file);
          },
          onSelect: function (item){
            const me = this
            this.$copyText(item.link).then(function () {
              me.showSnackbar = true;
            }, function (e) {
              alert('Can not copy\n'+e)
            })
          }
        },
        components: {
          navBar,
          fileUpload
        },
        mounted(){
          this.loadData()
        }
    }
</script>
<style scoped>
  .content{
    margin: 2% 5% 0 5%;
  }
  .container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  td{
    padding: 1%;
    width: 50%;
  }
</style>
